<template>
  <div class="p-fluid">
    <div class="p-col-12 p-lg-12 view-action">
      <div class="card card-w-title">
        <Toolbar class="toolbar-top">
          <template #left>
            <div class="manager-title">Thông Tin Admin</div>
          </template>
          <template #right>
            <Button
              label="Xoá tài khoản"
              @click="clickDelete()"
              class="p-button-warning callback-btn"
            />
            <Button
              label="Chỉnh sửa thông tin"
              @click="clickEdit()"
              class="p-button-warning callback-btn"
            />
          </template>
        </Toolbar>
        <Panel>
          <template slot="header">
            <span >Thông tin chung</span>
          </template>
          <div class="p-formgrid">
            <div class="p-card-field">
              <div class="p-field p-grid">
                <label class="p-col-fixed txt-right">Tên hiển thị (*)</label>
                <div class="p-col">
                  <label class="p-col-width disabled">{{ name }}</label>
                </div>
              </div>
              <div class="p-field p-grid">
                <label class="p-col-fixed txt-right">Email (*)</label>
                <div class="p-col">
                    <label class="p-col-width disabled">{{ email }}</label>
                </div>
              </div>
            </div>
          </div>
        </Panel>
      </div>
    </div>
    <Dialog
      header="Xác nhận"
      :visible.sync="showDeleteDialog"
      :contentStyle="{overflow: 'visible'}"
      :modal="true"
    >
      Bạn có chắc muốn xóa người dùng <strong>{{this.deleteName}}</strong>
      <template #footer>
        <Button label="Xóa" @click="deleteData()" icon="pi pi-check" class="p-button-danger" />
        <Button
          label="Hủy"
          @click="showDeleteDialog = false"
          icon="pi pi-times"
          class="p-button-warning callback-btn"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import DataServices from '@/core/DataServices'
// import BaseDatatable from './list'
export default {
  components: {
    // BaseDatatable
  },
  data() {
    return {
      name: '',
      email: '',
      // password: '',
      uid: '',
      uids: {},
      models: {},
      deleteId: null,
      deleteName: null,
      showDeleteDialog: false,
      pagination: true, // true cho phép phân trang
      paginationRows: 10, // số dòng trên 1 trang,
      listData: [],
      // dataModel: null,
      componentKey: 0
    }
  },
  async mounted () {
    // this.dataModel = DataServices.getModel('users');
    if (this.$route.params.id !== undefined && this.$route.params.id !== null) {
      await this.getData(this.$route.params.id);
    }
  },
  methods: {
    getField(col) {
      return col.key;
    },
    getLabel(col) {
      if (col.label) return col.label;
      else return col.name;
    },
    getHeaderStyle(col) {
      var style = "";
      if (col.width) style = "width: " + col.width + "em;";
      else if (col.name === "stt" || col.name === "STT") style = "width: 3em;";
      else if (col.type === "image" || col.type === "Image") style = "width: 6em;";
      return style;
    },
    getDataRows(col, row) {
      var value = '';
      if (col.type == "Text" && row[col.key] && row[col.key].length > 100) {
        var result = row[col.key].substr(0, row[col.key].lastIndexOf(" ", 100)) + "...";
        value = result;
      }
      return value
    },
    clickEdit() {
      this.$router.push({ path: '/admin/list-manager/edit/' + this.models.id, params: {id: this.models.id}});
    },
    backToList() {
      this.$router.push({ path: '/admin/list-manager' });
    },
    clickDelete() {
      this.deleteId = this.models.id
      this.deleteName = this.models.fullname
      this.showDeleteDialog = true
    },
    deleteData() {
      this.showDeleteDialog = false
      var query = DataServices.updateData('users')
      let variables = {
        'data_update': {
          'deleted': true,
          'active': false,
          'status': 0
        },
        'where_key': {
          'id': {'_eq': this.models.id}
        }
      };
      this.$apollo.mutate({
        mutation: query,
        variables: variables
      }).then((res) => {
        if (res.data[Object.keys(res.data)].affected_rows) {
          let acc_variables = {
            'data_update': {
              'deleted': true
            },
            'where_key': {
              'uid': {'_eq': this.models.id}
            }
          };
          this.$apollo.mutate({
            mutation: DataServices.updateData('accounts'),
            variables: acc_variables
          }).then(() => {
            // console.log(res);
            this.backToList();
          }).catch((error) => {
            console.error(error);
          })
        }
      }).catch((error) => {
        console.error(error);
      })
    },
    async getData(id) {
      var {data} = await this.$apollo.query({
        query: DataServices.getList('users', {"fields" : "id fullname email"}),
        variables: {
          where_key: {
            id: {'_eq': id}
          }
        },
        fetchPolicy: "network-only"
      })
      var model = data[Object.keys(data)];
      if (model.length > 0) {
        this.models = model[0];
        this.name = this.models.fullname;
        this.email = this.models.email;
        this.componentKey++;
      }
    }
  },
  apollo: {
  }
}
</script>
<style lang="scss">
.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only { padding: 0 !important; }
  }
}
.view-action{
  .toolbar-top{background: #fff; padding: 0; border: 0 none;margin-bottom: 16px; padding-bottom: 10px; border-bottom: 1px solid #EEEEEE;
    .manager-title{border: 0 none; padding: 0; margin: 0;}
    .p-toolbar-group-right {
      .p-button{margin-right: 8px; width: auto;
        &:last-child{margin-right: 0;}
      }
    }
  }
  .p-tabview{
    .p-tabview-panels{ padding: 0;}
    .p-tabview-nav{
      li{margin-right: 8px;
        .p-tabview-nav-link{border: 1px solid #B4B4B4; border-radius: 5px 5px 0px 0px !important; background: #eee; font-size: 15px;
          &:hover{text-decoration: none;}
        }
        &.p-highlight{
          .p-tabview-nav-link{background: #00A8FF !important;border: 1px solid #B4B4B4; border-radius: 5px;}
        }
        &:hover{
          .p-tabview-nav-link{background: #00A8FF !important; color: #fff !important;}
        }
      }
    }
  }
  .p-formgrid { margin: 0;
    .p-panel-header {color: #000000; font-size: 18px; font-weight: bold; background: #EEEEEE;}
    .p-card-field{ padding: 24px;
      .p-field{
        .p-col-fixed { width: 300px; display: inline-block !important; margin: 0;}
        .p-col-width{width:500px !important; border-radius: 5px;
          &.disabled{background: #EEEEEE; border: 0 none; padding: 8px 10px; color: #707070; height: 40px;}
          &.rows-4{height: 80px;}
          .file-select{margin: 0;
            input[type="file"]{display: none;}
            .select-button{display: inline-block; cursor: pointer; background: transparent; border-radius: 5px;  color: #000000; font-size: 13px; padding: 3px 16px;  border: 1px solid #B4B4B4;}
          }
        }
        .box-images{ margin-top: 10px; position: relative;
          .item-img{height: 150px;  margin-right: 8px; margin-bottom: 8px; display: inline-flex; position: relative; border: 0.5px solid #ccc;
            &.p-col-5{width: 18%;;}
            img{max-width: 100%; height: auto;margin: 0 auto; object-fit: contain;}
            .button-clear{width: 18px; height: 18px; background: #F5564E; float: right; cursor: pointer; position: absolute; right: 0px;
              img{vertical-align: top;}
            }
          }
        }
      }
    }
  }
  .p-rates{
    .top-rates{ padding: 16px;color: #000;border-bottom: 1px solid #B4B4B4; 
      label{margin: 0;}
      span{font-weight: 600; padding-left: 100px;}
    }
    .list-rates{padding: 16px;}
  }
}
</style>